import React from "react"
import { Link } from "gatsby"
import GatsbyBackgroundImage from 'gatsby-background-image' 

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


import '../main.scss'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

/** @jsx jsx */
import { css, jsx } from '@emotion/core'



// images
import curacaologo from "../img/curacao-logo-white.svg"
import curacao2 from '../img/Curacao_2.jpg'
import curacao3 from '../img/Curacao_3.jpg'
import curacao4 from '../img/Curacao_4.jpg'
import curacao5 from '../img/Curacao_5.jpg'
import curacaoAirplane from '../img/Curacao_Airplane.svg'
import curacaoBed from '../img/Curacao_BED-01.svg'
import curacaoStatue from '../img/Curacao_Statue.svg'
import curacaoSuitcase from '../img/Curacao_suitcase.svg'

const CuracaoPage = () => {
    const slickSettings = {
        dots:true,
        infinite:true,
        speed:500,
        slidesToShow:1,
        slidesToScroll:1
    }
    return (
    <Layout>
        <SEO title="Curacao" />
        <div className="all">
            <div id="cases">
                <div id="curacao" className="case">
                    <div className="close"><Link to="/">Go Back Home</Link></div>
                    <div className="mast shift curacao-bkg">
                        <img src={curacaologo} alt="HP" />
                        <h1><span>HP</span></h1>
                        <h2>Catch helps Curaçao craft an image while boosting an economy</h2>
                    </div>
                    <div className="intro">
                        <p>   Working with a destination as little-known as Curaçao, Catch worked to create a campaign that  defined this Caribbean nation and set it far apart from their neighbors. 
 <br /> <br />
 Through competitive research and a global discovery process, one clear insight emerged, those who had been could only sum it up with the words, “you just have to feel it for yourself.”  Thanks to the incredibly rich mix of the island’s Dutch colonial history, and a unique blend of Caribbean and European flavor, and the 35 beaches of sun, sand, and surf. 
 <br /> <br />
 With this differentiator, the island promoted an experience that couldn’t be explained, one you’d simply have to feel for yourself.  Visually we wanted to disrupt the sea of sameness that you would find in any travel publication with endless images of ocean blues. So we brought the colors of the island to life in the ads themselves to make the island further stand out from the crowded pack. 
 <br /> <br />
<strong>The results speak for themselves.</strong>
 </p>
                    </div>
                    <div className="work">
                        <ul>
                            <li><img src={curacao2} alt="" /></li>
                        </ul>
                    </div>
                    <div className="testmonial">
                        <p></p>
                    </div>
                    <div className="work">
                        <ul>
                            <li><img src={curacao3} alt="" /></li>
                        </ul>
                    </div>
                    <div className="testmonial">
                        <p></p>
                    </div>
                    <div className="work">
                        <ul>
                            <li>
                                <img src={curacao4} alt="" />
                                <div className="intro small">
                                    <p>
                                    When we came to overhauling the website, it was about creating an experience and a feel that not only mirrored the campaign but allowed potential travelers to plan out what they wanted to do on the island. 
<br /><br />
So we pinpointed moments in the travel cycle the website would have the most impact and developed a site that triggered emotional benefits and provided the practical information that travelers seek, no matter where they are coming from.
<br /><br />
With a refined visual language and content strategy, Curaçao built their brand in ways that weren't possible prior to the new website and consistently provide a seamless experience. 

                                    </p>
                                </div>
                            </li>
                            <li><img src={curacao5} alt="" /></li>
                            <li>
                                <div className="intro small">
                                    <h3>FEEL THE RESULTS FOR YOURSELF</h3>
                                    <div className="icon-holder airplane"><img src={curacaoSuitcase} /></div>
                                    <p>3 consecutive years of growth in stay-over arrivals from North American visitors</p>
                                    <div className="icon-holder airplane"><img src={curacaoAirplane} /></div>
                                    <p>2 additional North American airline carriers, adding an additional 10 flights per week</p>
                                    <div className="icon-holder airplane"><img src={curacaoBed} /></div>
                                    <p> 3 international hotel brands with ambitious new plans on the island </p>
                                    {/* <div className="icon-holder airplane"><img src={curacaoStatue} /></div>
                                    <p> 6 US DMAs targeted following campaign launch</p> */}
                                   


                                    



                                </div>
                            </li>
                            
                        </ul>
                    </div>
                </div>{/*//hp*/}
            </div>
        </div>{/* #all */}
    </Layout>
)}

export default CuracaoPage
